<template>
  <b-modal
    id="modal-interConsulta"
    size="lg"
    hide-footer
  >
    <template #modal-title>
      <b>Inter Consulta: 1</b>
    </template>
    <div class="row">
      <div class="col-sm-12">
        <b-tabs content-class="px-0 pb-0">
          <b-tab
            title="Solicitud"
            active
          >
            <div class="row mb-2">
              <div class="col-sm-2 text-right">
                <label class="col-form-label">
                  Solicitante:
                </label>
              </div>
              <div class="col-sm-5 pl-0">
                <multiselect
                  v-model="tabSolicitud.solicitanteSelected"
                  :options="tabSolicitud.listaSolicitantes"
                  placeholder=""
                  :allow-empty="false"
                  track-by="id"
                  label="name"
                  select-label=""
                  deselect-label="X"
                >
                  <span slot="caret" v-if="!(tabSolicitud.solicitanteSelected===null)"></span>
                  <span slot="noResult">Sin Resultados</span>
                  <span slot="noOptions">Lista Vacia</span>
                </multiselect>
              </div>
              <div class="offset-sm-1 col-sm-2 text-right">
                <label class="col-form-label">
                  Fecha:
                </label>
              </div>
              <div class="col-sm-2 pl-0">
                <date-picker
                  class="w-100"
                  v-model="tabSolicitud.fecha"
                  lang="es"
                  format="DD/MM/YYYY"
                  type="date"
                  value-type="date"
                ></date-picker>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col-sm-2 text-right">
                <label class="col-form-label">
                  Destinatario:
                </label>
              </div>
              <div class="col-sm-5 pl-0">
                <multiselect
                  v-model="tabSolicitud.destinatarioSelected"
                  :options="tabSolicitud.listaDestinatarios"
                  placeholder=""
                  :allow-empty="false"
                  track-by="id"
                  label="name"
                  select-label=""
                  deselect-label="X"
                >
                  <span slot="caret" v-if="!(tabSolicitud.destinatarioSelected===null)"></span>
                  <span slot="noResult">Sin Resultados</span>
                  <span slot="noOptions">Lista Vacia</span>
                </multiselect>
              </div>
              <div class="offset-sm-1 col-sm-2 text-right">
                <label class="col-form-label">
                  Hora:
                </label>
              </div>
              <div class="col-sm-2 pl-0">
                <input
                  v-model="tabSolicitud.hora"
                  type="time"
                  class="form-control"
                >
              </div>
            </div>
            <div class="row mx-0 mb-2">
              <div class="col-sm-12 py-2 custom-bg-gray">
                <div class="row py-1">
                  <div class="col-sm-3">
                    <h5 class="mb-0 font-weight-bold">Estado de Salud</h5>
                  </div>
                  <div class="col-sm-3">
                    <div class="form-check">
                      <input type="checkbox" id="tabSolicitud_checkBueno">
                      <label class="form-check-label h5 pl-2" for="tabSolicitud_checkBueno">
                        Bueno
                      </label>
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <div class="form-check">
                      <input type="checkbox" id="tabSolicitud_checkMalo">
                      <label class="form-check-label h5 pl-2" for="tabSolicitud_checkMalo">
                        Malo
                      </label>
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <div class="form-check">
                      <input type="checkbox" id="tabSolicitud_checkRegular">
                      <label class="form-check-label h5 pl-2" for="tabSolicitud_checkRegular">
                        Regular
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mx-0">
              <div class="col-sm-12 custom-bg-gray py-2">
                <div class="row mb-2">
                  <div class="col-sm-6">
                    <div class="row">
                      <div class="col-sm-12">
                        <b>Resumen Clínico:</b>
                      </div>
                      <div class="col-sm-12 pr-2">
                        <textarea
                          class="form-control resize-none border-dashed"
                          rows="6"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="row">
                      <div class="col-sm-12">
                        <b>Atenciones Realizadas:</b>
                      </div>
                      <div class="col-sm-12 pl-2">
                        <textarea
                          class="form-control resize-none border-dashed"
                          rows="6"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="row">
                      <div class="col-sm-12">
                        <b>Evolución del Paciente:</b>
                      </div>
                      <div class="col-sm-12 pr-2">
                        <textarea
                          class="form-control resize-none border-dashed"
                          rows="6"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="row">
                      <div class="col-sm-12">
                        <b>Motivo de la Inter Consulta:</b>
                      </div>
                      <div class="col-sm-12 pl-2">
                        <textarea
                          class="form-control resize-none border-dashed"
                          rows="6"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-sm-6">
                <span
                  class="col-form-label text-secondary font-weight-bold cursor-pointer d-inline-block"
                >
                  Imprimir Solicitud
                </span>
              </div>
              <div class="col-sm-6 text-right">
                <button class="btn btn-light mr-2">Cancelar</button>
                <button class="btn btn-success">Guardar</button>
              </div>
            </div>
          </b-tab>
          <b-tab
            title="Respuesta"
          >
            <div class="row mb-2">
              <div class="col-sm-2 text-right">
                <label class="col-form-label">
                  Solicitante:
                </label>
              </div>
              <div class="col-sm-4 pl-0">
                <multiselect
                  v-model="tabRespuesta.solicitanteSelected"
                  :options="tabRespuesta.listaSolicitantes"
                  disabled
                  placeholder=""
                  :allow-empty="false"
                  track-by="id"
                  label="name"
                  select-label=""
                  deselect-label="X"
                >
                  <span slot="caret" v-if="!(tabRespuesta.solicitanteSelected===null)"></span>
                  <span slot="noResult">Sin Resultados</span>
                  <span slot="noOptions">Lista Vacia</span>
                </multiselect>
              </div>
              <div class="col-sm-1 text-right">
                <label class="col-form-label">
                  Fecha:
                </label>
              </div>
              <div class="col-sm-2 pl-0 pr-2">
                <date-picker
                  class="w-100"
                  v-model="tabRespuesta.fecha"
                  disabled
                  lang="es"
                  format="DD/MM/YYYY"
                  type="date"
                  value-type="date"
                ></date-picker>
              </div>
              <div class="col-sm-1 text-right">
                <label class="col-form-label">
                  Hora:
                </label>
              </div>
              <div class="col-sm-2 pl-0">
                <input
                  v-model="tabRespuesta.hora"
                  disabled
                  type="time"
                  class="form-control"
                >
              </div>
            </div>
            <div class="row mb-4">
              <div class="col-sm-2 text-right">
                <label class="col-form-label">
                  Destinatario:
                </label>
              </div>
              <div class="col-sm-4 pl-0">
                <multiselect
                  v-model="tabRespuesta.destinatarioSelected"
                  :options="tabRespuesta.listaDestinatarios"
                  disabled
                  placeholder=""
                  :allow-empty="false"
                  track-by="id"
                  label="name"
                  select-label=""
                  deselect-label="X"
                >
                  <span slot="caret" v-if="!(tabRespuesta.destinatarioSelected===null)"></span>
                  <span slot="noResult">Sin Resultados</span>
                  <span slot="noOptions">Lista Vacia</span>
                </multiselect>
              </div>
             <!--  <div class="col-sm-1 pl-0 text-right">
                <label class="col-form-label">
                  Importe:
                </label>
              </div>
              <div class="col-sm-3 pl-0 pr-2">
                <vue-numeric
                  v-model="tabRespuesta.importe"
                  class="form-control"
                  separator=","
                  :precision="2"
                  placeholder=""
                />
              </div>
              <div class="col-sm-1 pl-0 text-left">
                <label class="col-form-label">
                  Bs.
                </label>
              </div> -->
            </div>

            <div class="row mx-0">
              <div class="col-sm-12 custom-bg-gray py-2">
                <div class="row mb-2">
                  <div class="col-sm-12">
                    <b>Informe:</b>
                  </div>
                  <div class="col-sm-12 pr-2">
                    <textarea
                      class="form-control resize-none border-dashed"
                      rows="6"
                    ></textarea>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <b>Recomendación:</b>
                  </div>
                  <div class="col-sm-12 pr-2">
                    <textarea
                      class="form-control resize-none border-dashed"
                      rows="6"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-sm-6">
                <span
                  class="col-form-label text-secondary font-weight-bold cursor-pointer d-inline-block"
                >
                  Imprimir Solicitud y Respuesta
                </span>
              </div>
              <div class="col-sm-6 text-right">
                <button class="btn btn-light mr-2">Cancelar</button>
                <button class="btn btn-success">Guardar</button>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Multiselect from 'vue-multiselect';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es';

export default {
  name: 'ModalInterConsulta',
  components: {
    Multiselect,
    DatePicker,
  },
  data() {
    return {
      tabSolicitud: {
        solicitanteSelected: null,
        listaSolicitantes: [],
        fecha: null,
        destinatarioSelected: null,
        listaDestinatarios: [],
        hora: '',
      },
      tabRespuesta: {
        solicitanteSelected: null,
        listaSolicitantes: [],
        fecha: null,
        destinatarioSelected: null,
        listaDestinatarios: [],
        hora: '',
        importe: 0,
      },
    };
  },
  methods: {
    guardarModal() {},
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.custom-bg-gray {
  background: #eee;
}
.resize-none {
  resize: none;
}
.border-dashed{
  border-style: dashed;
  border-width: 1px;
  border-color: gray;
}
</style>
